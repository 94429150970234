import type { TrackProperties } from "./generated-types/track"

import { useTrackEvent } from "@/modules/tracking/useTrackEvent"

export const useTrackGenericClickEvent = (): ((
  role: TrackProperties["event_click"]["properties"]["role"],
  eventProps?: Omit<TrackProperties["event_click"], "role" | "service">
) => void) => {
  const trackEvent = useTrackEvent()

  return (
    role: TrackProperties["event_click"]["properties"]["role"],
    eventProps?: Omit<TrackProperties["event_click"], "role" | "service">
  ) => {
    trackEvent("event_click", { ...eventProps, role, service: "generic_fe" })
  }
}
