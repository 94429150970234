import { useTrackGenericClickEvent } from "@/modules/tracking/useTrackGenericClickEvent"

const trackingRoles = {
  recruiters: "corporate_nav_recruiters",
  "services-carrieres": "corporate_nav_career_services",
  students: "corporate_nav_students",
} as const

export const useCorporateTrackingClick = (): ((
  type: keyof typeof trackingRoles | string,
  eventProps?: Parameters<ReturnType<typeof useTrackGenericClickEvent>>[1]
) => void) => {
  const trackEvent = useTrackGenericClickEvent()

  return (
    type: keyof typeof trackingRoles | string,
    eventProps?: Parameters<ReturnType<typeof useTrackGenericClickEvent>>[1]
  ) => {
    const role = trackingRoles[type as keyof typeof trackingRoles] || type
    trackEvent(role, { ...eventProps })
  }
}
