import type { TrackProperties } from "./generated-types/track"
import type { TrackEventPropertiesByEventName } from "./types"

import { useTracking } from "@/modules/tracking/useTracking"

export const useTrackEvent = (): (<EventName extends keyof TrackProperties>(
  eventName: EventName,
  eventProps: TrackEventPropertiesByEventName<EventName>
) => void) => {
  const tracking = useTracking()

  return <EventName extends keyof TrackProperties>(
    eventName: EventName,
    eventProps: TrackEventPropertiesByEventName<EventName>
  ) => {
    tracking?.track(eventName, { ...eventProps })
  }
}
